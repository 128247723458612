import React, { startTransition, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { hydrateRoot } from 'react-dom/client';
import Layout from './layout';
function hydrate() {
    startTransition(function () {
        var app = document.getElementById('app');
        app.classList.toggle('near-top', window.scrollY <= 98);
        hydrateRoot(app, React.createElement(BrowserRouter, null,
            React.createElement(CookiesProvider, null,
                React.createElement(StrictMode, null,
                    React.createElement(Layout, null)))));
        // threshold is the size of navbar in small and medium screens.
        var matchMedia = window.matchMedia('(max-width: 991.98)');
        var threshold = matchMedia.matches ? 59 : 97.484;
        matchMedia.addEventListener('change', function (e) {
            return app.classList.toggle('near-top', window.scrollY <= (threshold = e.matches ? 59 : 97.484));
        });
        // scroll, wheel, and touchmove events will be used to toggle #app's three classes:
        //   scroll-down: if scrolling direction is down (window's new scrollY > old scrollY).
        //   scroll-up: opposite of scroll-down.
        //   near-top: if window.scrollY <= 98.
        // scroll event is only used to toggle near-top while the other two work together to toggle the other two.
        // this is because hash fragment jumping is also a scroll event but not a wheel or touchmove event.
        window.addEventListener('scroll', function () { return app.classList.toggle('near-top', window.scrollY <= threshold); }, {
            passive: true,
        });
        app.addEventListener('wheel', function (e) {
            app.classList.toggle('scroll-down', e.deltaY > 0);
            app.classList.toggle('scroll-up', e.deltaY < 0);
        }, { passive: true });
        var clientY = 0;
        app.addEventListener('touchstart', function (e) { return (clientY = e.touches[0].clientY); }, { passive: true });
        app.addEventListener('touchmove', function (e) {
            var newClientY = e.changedTouches[0].clientY;
            app.classList.toggle('scroll-down', newClientY < clientY);
            app.classList.toggle('scroll-up', newClientY > clientY);
            clientY = newClientY;
        }, { passive: true });
    });
}
typeof requestIdleCallback === 'function' ? requestIdleCallback(hydrate) : setTimeout(hydrate, 1);
