import React from 'react';
import Container from 'react-bootstrap/Container';
export default function ErrorPage() {
    return (React.createElement(Container, { as: "main", className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "500"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Internal Server Error")),
        React.createElement("p", null,
            "Server has encountered an unknown error.",
            React.createElement("br", null),
            "Please reach out to the site administrator for assistance."),
        React.createElement("a", { className: "text-decoration-none", href: "/public" }, "Home")));
}
