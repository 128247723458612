import React from 'react';
import Container from 'react-bootstrap/Container';
export default function NotFound() {
    return (React.createElement(Container, { className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "404"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Page Not Found")),
        React.createElement("p", null,
            "Do you know where you're going to?",
            React.createElement("br", null),
            "Do you like the things that life is showing you?",
            React.createElement("br", null),
            "Where are you going to?",
            React.createElement("br", null),
            "Do you know",
            React.createElement("a", { className: "text-decoration-none", href: "https://www.youtube.com/watch?v=gsA-Xc6gWDE", rel: "external nofollow noopener" }, "?")),
        React.createElement("a", { className: "text-decoration-none", href: "/public" }, "Home")));
}
