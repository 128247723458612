import './layout.scss';
import { createRoutesFromElements, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'components/error-page';
import NotFound from 'components/not-found';
import Spinner from 'react-bootstrap/Spinner';
export default function Layout() {
    return (React.createElement(ErrorBoundary, { fallbackRender: ErrorPage },
        React.createElement(Routes, null, routes)));
}
var routes = [
    React.createElement(Route, { key: "home", index: true, path: "/", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(Container, null,
                React.createElement("p", { className: "lead" }, "Under construction"))) }),
    React.createElement(Route, { key: "404", path: "*", element: React.createElement(NotFound, null) }),
];
// used by server to check early if a route exists or not to return an early 404.
export var appRoutes = routes.map(function (r) { return createRoutesFromElements(r); }).reduce(function (a, b) { return a.concat(b); });
function Fallback() {
    return (React.createElement(Container, { as: "main" },
        React.createElement("div", { className: "text-center w-100" },
            React.createElement(Spinner, { animation: "border" }))));
}
